import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import ImageHeader from '../../components/ImageHeader'
import IndexFilter from '../../components/IndexFilter'
import Layout from "../../components/Layout"
import Seo from '../../components/Seo'
import {useStaticQuery, graphql} from 'gatsby'
import '../../styles/index.scss'

const PerspectivesPage = () => {
    const data = useStaticQuery(graphql`
        query PerspectiveQuery {
            allWpPerspective {
                nodes {
                    name
                    id
                    slug
                    count
                }
            }
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        headerImage {
                            sourceUrl
                        }
                    }
                }
            }
        }
    `)

    //get all topics with a parent of null and map them
    //while mapping parent topics, find and map all children topics with matching parent dbid
    return (
        <Layout>
            <Seo
                title="Perspectives"
                description="Perspectives Index"
            />
            <ImageHeader title="Perspective Index"
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Perspectives" isResource={true} />
            <IndexFilter taxonomy="topics" />
            <section className="index container">
                    <div className="index__group">
                        <ul className="index__group__children">
                            {data.allWpPerspective.nodes.filter((term) => term.count > 0).map((term, index) =>
                                <li key={index} className="index__group__children__child">
                                    <a href={`/resources/perspectives/${term.slug}`}>
                                        <div>
                                            {term.name}
                                        </div>
                                        <span>
                                            {`${term.count.toString()} resources`}
                                        </span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
            </section>
            <Breadcrumbs title="Perspectives" isResource={true} />
        </Layout>
    )
}

export default PerspectivesPage